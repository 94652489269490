<template>
  <v-main>
    <Header
      :title="pageTitle"
      :subtitle="pageSubtitle"
      :items-bread-crumb="itemsBreadCrumb"
      :has-add-button="false"
      :has-update-info="false"
    ></Header>
    <AGCard>
        
    </AGCard>
  </v-main>
</template>

<script>

export default {
  name: 'CBOForm',
  components: {
    Header: () => import('../../../commons/Header.vue'),
    AGCard: () => import('../../../commons/AGCard.vue'),
  },

  props: {
    isEdit: {
        type: Boolean,
        default: false
    },
    pageTitle: {
        type: String,
        default: 'Adicionar ocupação'
    }
  },

  data() {
    return {
      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true,
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Parâmetros de análise ',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/analysis-parameters',
        },
        {
          title: 'Classificação Brasileira de Ocupações - CBO',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/analysis-parameters/cbo',
        },
        {
          title: 'Adicionar ocupação',
          disabled: true,
          active: true,
        },
      ],
    };
  },

  computed: {
    pageSubtitle() {
        return this.isEdit ? 'Você está visualizando a página de detalhamento da ocupação selecionada' : 'Preencha as informações abaixo';
    }
  }
};
</script>

<style scoped lang="scss"></style>
