import { render, staticRenderFns } from "./CBOForm.vue?vue&type=template&id=57659c82&scoped=true"
import script from "./CBOForm.vue?vue&type=script&lang=js"
export * from "./CBOForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57659c82",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57659c82')) {
      api.createRecord('57659c82', component.options)
    } else {
      api.reload('57659c82', component.options)
    }
    module.hot.accept("./CBOForm.vue?vue&type=template&id=57659c82&scoped=true", function () {
      api.rerender('57659c82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/cadastros-gerenciais/e-social/components/CBOForm.vue"
export default component.exports