var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: _vm.pageTitle,
          subtitle: _vm.pageSubtitle,
          "items-bread-crumb": _vm.itemsBreadCrumb,
          "has-add-button": false,
          "has-update-info": false,
        },
      }),
      _c("AGCard"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }